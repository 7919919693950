import React from 'react'
import intl from 'react-intl-universal'
import { loadTranslations } from '../actions/RmaActions'
import { setHighContrast } from '../actions/AuthActions'
import { ReactComponent as SelectArr } from '../assets/select-arr.svg'
import { ReactComponent as WorldIcon } from '../assets/world.svg'
import { ReactComponent as ContrastIcon } from '../assets/contrast.svg'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  high_contrast: state.auth.high_contrast,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
  loadTranslationsAction: (lang) => dispatch(loadTranslations(lang)),
  setHighContrastAction: (high_contrast) => dispatch(setHighContrast(high_contrast))
})

class FooterComponent extends React.Component {
  componentDidMount() {
    const { high_contrast } = this.props
    const val = high_contrast && high_contrast === 'true'
    if(val){
      document.querySelector('html').classList.toggle('high_contrast')
    }
    this.setLogoContrast(val)
  }

  renderLocaleSelector() {
    const { lang } = this.props
    return (
      <>
        <WorldIcon/>
        <select className="lang" onChange={this.onSelectLocale} defaultValue={lang}>
          <option value="" disabled>Język</option>
          {this.props.locales.map(locale => (
            <option key={locale.value} value={locale.value}>{locale.name}</option>
          ))}
        </select>
        <SelectArr/>
      </>
    )
  }

  setLogoContrast(high_contrast){
    if(high_contrast){
      document.querySelector('.logoStyle').innerHTML = document.querySelector('.logoStyle').innerHTML.replace('company_logo', 'company_logo_black')
    } else {
      document.querySelector('.logoStyle').innerHTML = document.querySelector('.logoStyle').innerHTML.replace('company_logo_black', 'company_logo')
    }
  }

  toggleContrastMode = () => {
    const { setHighContrastAction } = this.props
    const body = document.querySelector('html')
    body.classList.toggle('high_contrast')
    const val = body.classList.contains('high_contrast')
    this.setLogoContrast(val)
    setHighContrastAction(val)
  }

  renderContrastSwitcher() {
    return (
      <button className="contrast_switcher"
              onClick={this.toggleContrastMode}>
        <ContrastIcon/>
      </button>
    )
  }

  onSelectLocale = (e) => {
    const {loadTranslationsAction} = this.props
    const lang = e.target.value
    loadTranslationsAction(lang)
  }

  render() {
    return (
      <footer>
        <div className="container">
          <p className="pull-right">
            {this.renderLocaleSelector()}
            {this.renderContrastSwitcher()}
            <span className="opacity-3 mx-3"> | </span>
            Powered by <a href="https://www.ideaerp.pl/" rel="noopener noreferrer" target="_blank">IDEAerp</a>
          </p>
          <p>Copyright <a href="https://www.ideacommercesolutions.pl" rel="noopener noreferrer" target="_blank">IDEA Commerce Solutions</a>. {intl.get('Wszelkie prawa zastrzeżone')}.</p>
        </div>
      </footer>
    )
  }
}

const Done = connect(mapStateToProps, mapDispatchToProps)(FooterComponent)
export default Done
